import React from "react"

import * as homeAboutStyles from './home-about.module.css'

const HomeAbout = () =>  {

  return (
    <section className={homeAboutStyles.outer}>
      <section className={homeAboutStyles.inner}>
        <div style={{
          width: `250px`,
          height: `3px`,
          backgroundColor: `#FFF`,
          margin: `0 auto`,
          marginBottom: `0.3rem`,
        }}/>
      <h1>Czym się zajmujemy?</h1>
      <p>
      Studenckie Koło Naukowe Silesian Aerospace Technologies zrzesza pasjonatów technologii kosmicznych. Studenckie Koło SAT specjalizuje się w budowie sond stratosferycznych i rakiet, komunikacji satelitarnej i projektowaniu komponentów do satelitów typy CubeSat.
      </p>
      </section>
    </section>
  )
}

export default HomeAbout
