import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeAbout from "../components/home-about"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query{
      file(relativePath: {eq: "polsl-background-logo.png"}) {
        childImageSharp {
          fluid(maxWidth: 2546, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (


  <Layout>
    <SEO title="Home" />

    <Img fluid={data.file.childImageSharp.fluid} />

    <HomeAbout />

  </Layout>
)}

export default IndexPage
